import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SpacesSyncGuard } from '@memberspot/admin/community/data-access/spaces';
import {
  hubspotAppRedirectGuard,
  zohoAppRedirectGuard,
} from '@memberspot/admin/integration/data-access/school-apps';
import { DashboardSyncGuard } from '@memberspot/admin/memberarea/data-access/dashboard';
import { CustomerGuard } from '@memberspot/admin/shared/data-access/customers';
import { MemberAreaGuard } from '@memberspot/admin/shared/data-access/memberarea';
import { OffersGuard } from '@memberspot/admin/shared/data-access/offers';
import { PagesGuard } from '@memberspot/admin/shared/data-access/pages';
import {
  GeneralPermissionGuard,
  SchoolAdminPermissionSyncGuard,
} from '@memberspot/admin/shared/data-access/school-admin-permission';
import { SubscriptionGuard } from '@memberspot/admin/shared/service/subscription';
import { RouteData } from '@memberspot/admin/shared/util/model';
import { SyncAllCoursesGuard } from '@memberspot/frontend/shared/data-access/all-courses';
import { VersionComponent } from '@memberspot/frontend/shared/ui/tools';

import { AuthGuard } from './common/guards/auth.guard';
import { leadQualifiedFnGuard } from './common/guards/lead-qualified.guard';
import { SchoolAdminGuard } from './common/guards/school-admin.guard';
import { AppBlankComponent } from './shared/layouts/blank/blank.component';
import { ErrorComponent } from './shared/layouts/error/error.component';
import { FullViewComponent } from './shared/layouts/full/full-view/full-view.component';

const routes: Routes = [
  {
    path: 'version',
    component: VersionComponent,
  },
  {
    path: 'auth',
    component: AppBlankComponent,
    data: {
      breadcrumb: {
        skip: true,
      },
    },
    loadChildren: () =>
      import('./auth/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: '404',
    component: ErrorComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    children: [
      {
        path: 'qualify-lead',
        component: AppBlankComponent,
        loadChildren: () =>
          import('@memberspot/admin/auth/feature/qualify-lead').then(
            (mod) => mod.AdminAuthFeatureQualifyLeadModule,
          ),
      },
      {
        path: '',
        component: FullViewComponent,
        canActivate: [leadQualifiedFnGuard],
        children: [
          {
            path: 'schools',
            data: {
              breadcrumb: {
                skip: true,
                label: 'breadcrumbs.admin.schools',
                info: 'translate',
                icon: 'hero-rectangle-group',
              },
            },
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('@memberspot/admin/school/shell').then(
                    (m) => m.SchoolRoutes,
                  ),
              },
            ],
          },
          {
            path: 'profile',
            data: {
              breadcrumb: {
                label: 'Dein Profil',
                icon: '',
                skip: true,
              },
            },
            loadChildren: () =>
              import('@memberspot/admin/auth/feature/profile').then(
                (mod) => mod.AdminAuthFeatureProfileModule,
              ),
          },
          {
            path: 'stats',
            data: {
              breadcrumb: { label: 'Statistiken', icon: '' },
            },
            loadChildren: () =>
              import('@memberspot/admin/statistics/shell').then(
                (mod) => mod.AdminStatisticsShellModule,
              ),
          },
          {
            path: ':schoolId',
            canActivate: [SchoolAdminGuard],
            canDeactivate: [SchoolAdminGuard],
            data: {
              breadcrumb: {
                skip: true,
              },
            },
            children: [
              {
                path: '',
                canActivate: [
                  CustomerGuard,
                  SyncAllCoursesGuard,
                  SchoolAdminPermissionSyncGuard,
                  SpacesSyncGuard,
                  MemberAreaGuard,
                ],
                canDeactivate: [
                  CustomerGuard,
                  SyncAllCoursesGuard,
                  OffersGuard,
                  SchoolAdminPermissionSyncGuard,
                  MemberAreaGuard,
                ],
                data: {
                  breadcrumb: {
                    skip: true,
                  },
                },
                children: [
                  {
                    path: '',
                    redirectTo: 'home',
                    pathMatch: 'full',
                  },
                  {
                    path: 'dpa-not-accepted',
                    loadComponent: () =>
                      import('@memberspot/admin/school/feature/dpa').then(
                        (m) => m.DpaNotAcceptedComponent,
                      ),
                  },
                  {
                    path: '',
                    canActivate: [DashboardSyncGuard, PagesGuard],
                    canDeactivate: [PagesGuard],
                    children: [
                      {
                        path: 'preview',
                        data: <RouteData>{
                          breadcrumb: {
                            label: 'breadcrumbs.admin.memberArea',
                            info: 'translate',
                            icon: 'hero-rectangle-group',
                          },
                          neededPermission: 'yourArea',
                        },
                        canActivate: [
                          SubscriptionGuard,
                          GeneralPermissionGuard,
                        ],
                        children: [
                          {
                            path: '',
                            loadChildren: () =>
                              import('@memberspot/admin/memberarea/shell').then(
                                (mod) => mod.AdminMemberareaShellModule,
                              ),
                          },
                        ],
                      },
                      {
                        path: '',
                        children: [
                          {
                            path: 'navigation',
                            canActivate: [GeneralPermissionGuard],
                            data: <RouteData>{
                              breadcrumb: {
                                label: 'admin.menuItems.sidebar.name',
                                info: 'translate',
                                icon: 'hero-list-bullet',
                              },
                              neededPermission: 'sidenav',
                            },
                            loadChildren: () =>
                              import(
                                '@memberspot/admin/side-navigation/shell'
                              ).then(
                                (mod) => mod.AdminSideNavigationShellModule,
                              ),
                          },
                          {
                            path: 'portals',
                            canActivate: [GeneralPermissionGuard],
                            data: <RouteData>{
                              breadcrumb: {
                                label: 'admin.menuItems.clientDashboards.name',
                                info: 'translate',
                                icon: 'hero-list-bullet',
                              },
                              neededPermission: 'portals',
                            },
                            loadChildren: () =>
                              import(
                                '@memberspot/admin/memberarea/feature/client-dashboard-overview'
                              ).then(
                                (mod) =>
                                  mod.AdminMemberareaFeatureClientDashboardOverviewModule,
                              ),
                          },
                          {
                            path: 'pages',
                            canActivate: [GeneralPermissionGuard],
                            data: <RouteData>{
                              breadcrumb: {
                                // skip: true,
                                label: 'admin.menuItems.pages.name',
                                info: 'translate',
                                icon: 'hero-list-bullet',
                              },
                              neededPermission: 'pages',
                            },
                            loadChildren: () =>
                              import(
                                '@memberspot/admin/memberarea/feature/pages'
                              ).then(
                                (mod) => mod.AdminMemberareaFeaturePagesModule,
                              ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'users',
                    canActivate: [SubscriptionGuard, GeneralPermissionGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.memberAdministration',
                        info: 'translate',
                        icon: 'hero-user-group',
                      },
                      neededPermission: 'members',
                    },
                    loadChildren: () =>
                      import(
                        '@memberspot/admin/school-user/feature/overview'
                      ).then((mod) => mod.AdminSchoolUserFeatureOverviewModule),
                  },
                  {
                    path: 'settings',
                    canActivate: [OffersGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.settings.overview',
                        info: 'translate',
                        icon: 'hero-cog-8-tooth',
                      },
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/management/shell').then(
                        (mod) => mod.AdminManagementShellModule,
                      ),
                  },
                  {
                    path: 'courses',
                    canActivate: [],
                    data: {
                      breadcrumb: {
                        label: 'breadcrumbs.admin.courses',
                        info: 'translate',
                        icon: 'hero-academic-cap',
                      },
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/course/shell').then(
                        (mod) => mod.AdminCourseShellModule,
                      ),
                  },
                  {
                    path: 'home',
                    canActivate: [],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.home',
                        info: 'translate',
                        icon: 'hero-home-solid',
                      },
                      neededPermission: 'home',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/home/shell').then(
                        (mod) => mod.AdminHomeShellModule,
                      ),
                  },
                  {
                    path: 'dashboard',
                    canActivate: [GeneralPermissionGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.courses',
                        info: 'translate',
                        icon: 'hero-academic-cap',
                      },
                      neededPermission: 'dashboard',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/dashboard/shell').then(
                        (mod) => mod.AdminDashboardShellModule,
                      ),
                  },
                  {
                    path: 'offers',
                    canActivate: [SubscriptionGuard, GeneralPermissionGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.accesses',
                        info: 'translate',
                        icon: 'hero-rectangle-stack',
                      },
                      neededPermission: 'access',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/offer/shell').then(
                        (m) => m.OfferRoutes,
                      ),
                  },
                  {
                    path: 'exams',
                    canActivate: [SubscriptionGuard, GeneralPermissionGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.exams',
                        info: 'translate',
                        icon: 'hero-question-mark-circle',
                      },
                      neededPermission: 'exams',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/exam/shell').then(
                        (mod) => mod.AdminExamShellModule,
                      ),
                  },
                  {
                    path: 'integrations',
                    canActivate: [SubscriptionGuard, GeneralPermissionGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.admin.integrations',
                        info: 'translate',
                        icon: 'hero-bolt',
                      },
                      neededPermission: 'integrations',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/integration/shell').then(
                        (module) => module.AdminIntegrationShellModule,
                      ),
                  },
                  {
                    path: 'team',
                    canActivate: [GeneralPermissionGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        skip: true,
                        label: 'Team',
                        icon: 'hero-user-plus',
                      },
                      neededPermission: 'team',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/team/shell').then(
                        (mod) => mod.AdminTeamShellModule,
                      ),
                  },
                  {
                    path: 'spaces',
                    canActivate: [GeneralPermissionGuard, OffersGuard],
                    data: <RouteData>{
                      breadcrumb: {
                        label: 'breadcrumbs.spaces',
                        info: 'translate',
                        icon: 'hero-chat-bubble-left-ellipsis',
                      },
                      neededPermission: 'spaces',
                    },
                    loadChildren: () =>
                      import('@memberspot/admin/community/feature/shell').then(
                        (mod) => mod.AdminCommunityFeatureShellModule,
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: 'add-integration/hubspot',
            data: <RouteData>{
              breadcrumb: {
                skip: true,
              },
            },
            canActivate: [hubspotAppRedirectGuard],
            component: ErrorComponent,
          },
          {
            path: 'add-integration/zoho',
            data: <RouteData>{
              breadcrumb: {
                skip: true,
              },
            },
            canActivate: [zohoAppRedirectGuard],
            component: ErrorComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
      enableViewTransitions: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
